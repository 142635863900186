import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.showImage),callback:function ($$v) {_vm.showImage=$$v},expression:"showImage"}},[_c(VImg,{attrs:{"src":_vm.showImageUrl,"max-height":"100%"},on:{"click":function($event){_vm.showImage=false}}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"loading":_vm.isLoading},on:{"click":_vm.generateMenu}},[_vm._v(" Generate Menu ")]),_c(VSpacer),_c('router-link',{attrs:{"to":{name:'MenuEdit'}}},[_vm._v("edit menu")]),_c(VSpacer),_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Picker in menu","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.isSunday},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c(VSpacer)],1)],1)],1),(_vm.error)?_c(VCardText,[_c(VAlert,{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.error))])],1):_vm._e(),_c(VCardText,[_c(VRow,[(_vm.menu)?_c(VCol,[_c(VBtnToggle,{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},_vm._l((Object.keys(_vm.menu)),function(stream){return _c(VBtn,{key:stream,attrs:{"value":stream}},[_vm._v(_vm._s(stream)+" ")])}),1),_vm._l((_vm.activeMenu),function(ref,stream){
var menu = ref.menu;
var updated_at = ref.updated_at;
return _c(VCard,{key:stream},[_c(VCardTitle,[_c('h1',[_vm._v(_vm._s(_vm.getStreamName(stream))+" ("+_vm._s(stream)+")")])]),_vm._l((menu),function(ref,index){
var date = ref.date;
var meals = ref.meals;
var products = ref.products;
return _c(VCardText,{key:index},[(date!=='any')?_c('h2',[_vm._v("Meals on "+_vm._s(_vm.dateFormatted(date)))]):_vm._e(),(date==='any')?_c('h2',[_vm._v("Chef Choice Meals")]):_vm._e(),_vm._l(((products||meals)),function(meal,mealIndex){return _c(VCard,{key:mealIndex,staticClass:"my-4"},[_c(VCardTitle,[_vm._v(" "+_vm._s(date === 'any' ? '' : _vm.dateFormatted(date))+" "+_vm._s(meal.tod)+" "),(meal.deliveryDate)?_c('span',[_vm._v("(delivery "+_vm._s(_vm.dateFormatted(meal.deliveryDate))+")")]):_vm._e(),_c(VSpacer),_c(VChip,{attrs:{"outlined":""}},[_vm._v(_vm._s(meal.stream))])],1),_c(VCardText,[_c('h3',[(meal.price)?[_vm._v(_vm._s(_vm.formatCurrency(meal.price)))]:_vm._e(),_vm._v(" "+_vm._s(meal.name)+" (M"+_vm._s(meal.id)+") ")],2),_c('p',[_vm._v(_vm._s(meal.ingredients))]),_c(VRow,_vm._l(((meal.photos||{})),function(url,slot){return _c(VCol,{key:slot,attrs:{"cols":"3"}},[_c('h4',[_vm._v(_vm._s(slot))]),_c(VImg,{attrs:{"src":url},on:{"click":function($event){_vm.showImage=true; _vm.showImageUrl=url}}})],1)}),1)],1)],1)})],2)})],2)})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }